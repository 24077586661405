// extracted by mini-css-extract-plugin
export var bigText = "hero-module--bigText--7faa1";
export var btn = "hero-module--btn--687b4";
export var fullScreen = "hero-module--fullScreen--0e963";
export var homeContent = "hero-module--homeContent--84913";
export var homeText = "hero-module--homeText--06d90";
export var imgWrap = "hero-module--imgWrap--9ea09";
export var logoHome = "hero-module--logoHome--bbfb5";
export var overlay = "hero-module--overlay--6e08d";
export var screenReaderText = "hero-module--screenReaderText--4a410";
export var smallText = "hero-module--smallText--c0e66";
export var textHeader = "hero-module--textHeader--d5126";
export var videoWrap = "hero-module--videoWrap--b5512";