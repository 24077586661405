import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
const MuxVideo = loadable(() => import('@mux/mux-video-react'));
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import Logo from '../logo';
import * as styles from './hero.module.css';
import { useBreakpoint } from '../../lib/helpers';
import LogoSvg from '../LogoSvg';
import { BoltColor } from '../../constants';
import LogoGradient from '../LogoGradient';

const Hero = () => {
  const { sanitySiteSettings, sanityCarousel } = useStaticQuery(graphql`
    query HeaderQuery {
      sanitySiteSettings {
        heroVideo {
          asset {
            url
            assetId
            _id
            mimeType
          }
        }
        heroMuxVideo {
          video {
            asset {
              playbackId
            }
          }
        }
        heroImage {
          alt
          asset {
            url
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            _id
            _rev
            assetId
          }
        }
      }
      sanityCarousel(title: { eq: "Header Carousel" }) {
        specialties
        slides {
          bigText
          smallItalicText
        }
      }
    }
  `);
  const [isMobile, setIsMobile] = useState(false);
  const issmMin = useBreakpoint('smMin');

  const { heroVideo, heroImage, heroMuxVideo } = sanitySiteSettings;
  const { specialties, slides } = sanityCarousel;

  let globalWindow = null;
  useEffect(() => {
    const video = document.getElementById('heroVideoId');
    if (video && !heroMuxVideo?.video?.asset?.playbackId) {
      video.play();
    }
    if (typeof window !== `undefined`) {
      globalWindow = window;
    }

    if (!issmMin) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  const scrollDown = () => {
    globalWindow.scrollTo({ top: globalWindow.innerHeight, behavior: 'smooth' });
  };

  return (
    <header className={styles.fullScreen} id="home">
      {heroVideo && (
        <video autoPlay muted loop id="heroVideoId" className={styles.videoWrap}>
          <source src={heroVideo.asset.url} type={heroVideo.asset.mimeType} />
        </video>
      )}
      {heroMuxVideo && heroMuxVideo?.video?.asset?.playbackId && !heroVideo.asset && (
        <MuxVideo
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            top: '0',
            left: '0',
            position: 'absolute',
          }}
          playbackId={heroMuxVideo?.video?.asset?.playbackId}
          streamType="on-demand"
          metadata={{
            video_title: 'Hero Mux Video Eric Nation Portfolio',
          }}
          autoPlay
          muted
          loop
        />
      )}
      {heroImage && heroImage.asset && (
        <div
          className={styles.imgWrap}
          style={{
            position: 'absolute',
            backgroundImage: `url(${heroImage.asset.url})`,
          }}
        />
      )}
      <div className={styles.homeContent}>
        <div className={styles.logoHome}>
          {/* <LogoSvg width={90} EColor={isMobile ? '#000000' : '#ffffff'} BoltColor={BoltColor} /> */}
          <LogoGradient width={isMobile ? 60 : 90} EColor={isMobile ? '#000000' : '#ffffff'} />
        </div>

        <div className={styles.homeText}>
          {slides && slides.length && (
            <CarouselProvider isPlaying infinite isIntrinsicHeight totalSlides={slides.length}>
              <Slider>
                {slides.map((slide, i) => {
                  return (
                    <Slide index={i} key={`slide_${i}`}>
                      <span className={styles.textHeader}>{slide.smallItalicText}</span>
                      <span className={styles.bigText}>{slide.bigText}</span>
                    </Slide>
                  );
                })}
              </Slider>
            </CarouselProvider>
          )}
          <h1 className={styles.screenReaderText}>
            Portfolio and Blog of Eric Nation, Software Developer
          </h1>
          {specialties && specialties.length && (
            <div className={styles.smallText}>
              {specialties.map((specialty, index) => (index ? ' - ' : '') + specialty)}
            </div>
          )}
        </div>
        <div className={styles.homeMore}>
          <button type="button" onClick={() => scrollDown()} className={styles.btn}>
            More
          </button>
        </div>
      </div>
    </header>
  );
};

export default Hero;
